<div class="mat-mdc-dialog-container">
  <div class="container-template">
    <table class="card-attendance-msg">
      <thead class="text-align-left">
        <tr>
          <th class="header-name">Nome do modelo</th>
          <th class="header-preview">Prévia</th>
          <th class="header-language">Idioma</th>
          <th class="header-action"></th>
        </tr>
      </thead>
      <tr>
        <td class="template-name">
          <div>{{ selectedTemplate?.name }}</div>
        </td>
        <td class="template-preview">
          {{ selectedTemplate?.bodyComponent?.text | slice : 0 : 50 }}...
        </td>
        <td class="template-language">
          <ng-container [ngSwitch]="selectedTemplate?.language">
            <ng-container *ngSwitchCase="'pt_BR'">
              <div class="language">
                <img width="15" src="../../../../assets/icons/br.svg" alt="" />
                <h6>Português</h6>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'en_US'">
              <div class="language">
                <img width="15" src="../../../../assets/icons/us.svg" alt="" />
                <h6>Inglês</h6>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ selectedTemplate?.language }}
            </ng-container>
          </ng-container>
        </td>
        <td class="template-action">
          <button>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.7076 9.70806L12.7076 25.7081C12.6147 25.801 12.5044 25.8748 12.383 25.9251C12.2616 25.9754 12.1315 26.0013 12.0001 26.0013C11.8687 26.0013 11.7385 25.9754 11.6171 25.9251C11.4957 25.8748 11.3854 25.801 11.2926 25.7081L4.29257 18.7081C4.10493 18.5204 3.99951 18.2659 3.99951 18.0006C3.99951 17.7352 4.10493 17.4807 4.29257 17.2931C4.48021 17.1054 4.7347 17 5.00007 17C5.26543 17 5.51993 17.1054 5.70757 17.2931L12.0001 23.5868L27.2926 8.29306C27.4802 8.10541 27.7347 8 28.0001 8C28.2654 8 28.5199 8.10541 28.7076 8.29306C28.8952 8.4807 29.0006 8.73519 29.0006 9.00056C29.0006 9.26592 28.8952 9.52042 28.7076 9.70806Z"
                fill="#039855"
              />
            </svg>
          </button>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <div class="container">
      <h3>Editar Variáveis</h3>
      <div class="main-row">
        <div class="variable">
          <form [formGroup]="form">
            <div
              *ngFor="let variable of templateVariableList; let i = index"
              class="variable"
            >
              <h3 class="title-prev" *ngIf="variable !== '{{midiaHeader}}'">Corpo variável {{ i + 1 }}</h3>
              <h3 class="title-prev" *ngIf="variable === '{{midiaHeader}}'">Link para {{this.midiaType}} do template</h3>
              <div class="variable-input">
                <input
                  class="input-template"
                  type="text"
                  [formControlName]="'var' + (i + 1)"
                  placeholder="Nome"
                  [disabled]="isActive"
                  [readOnly]="isActive"
                />
                <button [matMenuTriggerFor]="menuVar" class="var-button">
                  Variaveis
                </button>
                <mat-menu #menuVar="matMenu">
                  @for(request of requestsList | keyvalue; track $index) {
                  <button
                    mat-menu-item
                    (click)="!isActive && selectVarWebhookRequest(i + 1, request.key, false, variable, request.value)"
                  >
                    {{ request.key }}
                  </button>
                  }
                  <button mat-menu-item (click)="!isActive && selectVarOperatorName(i + 1)">
                    Nome atendente
                  </button>
                </mat-menu>
              </div>
            </div>
          </form>
        </div>

        <div class="">
          <div class="main-preview">
            <h3 class="title-preview">Prévia do template</h3>
            <div class="preview">
              <div class="prev-content">
                <div class="header-img">
                  <loading-component
                    class="loading-template"
                    *ngIf="loadingSpinnerTemplate$ | async"
                  ></loading-component>
                  <div
                    class="flex items-center justify-center w-[100%] custom-max:w-[100%] mobile:w-[100%]"
                    *ngIf="previewHeader"
                  >
                    <label
                      for="dropzone-file"
                      class="flex flex-col items-center justify-center w-[100%] h-38 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-5 pb-6 boreder"
                      >
                        <svg
                          *ngIf="!midiaHeader"
                          width="57"
                          height="56"
                          viewBox="0 0 57 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4.5"
                            y="4"
                            width="48"
                            height="48"
                            rx="24"
                            fill="#E6E6FF"
                          />
                          <rect
                            x="4.5"
                            y="4"
                            width="48"
                            height="48"
                            rx="24"
                            stroke="#F1F1FF"
                            stroke-width="8"
                          />
                          <g clip-path="url(#clip0_6074_436)">
                            <path
                              d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                              stroke="#4213F6"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6074_436">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(16.5 16)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <div *ngIf="!midiaHeader">
                          <p
                            class="mb-2 text-sm text-gray-500 dark:text-gray-400 p-2"
                          >
                            <span class="font-semibold text-[#4213F6] text-xl"
                              >Clique aqui para carregar</span
                            >
                            <br /><span
                              class="flex items-center justify-center"
                            >
                              ou arraste e solte
                            </span>
                          </p>
                          <p class="text-xs text-gray-500 dark:text-gray-400">
                            PNG, JPG ou JPEG (MAX. 800x400px)
                          </p>
                        </div>
                        <img
                          *ngIf="midiaHeader && typeFile === 'image'"
                          [src]="midiaHeader"
                          alt="image"
                        />

                        <div class="w-[230px] items-center" *ngIf="midiaHeader && typeFile !== 'image'">
                          <a class=" space-x-2 text-black" [href]="midiaHeader" target="_blank">
                
                            <div class="w-[200px] items-center flex">
                              <div class="w-[50px]">
                                <img [src]="getIconForContentType()">
                              </div>
                              <h1 class=" mt-2 ml-[10px] w-[300px] items-center">Clique aqui para abrir {{typeFile === 'image'? 'sua' : 'seu'}} {{typeFile}}.</h1>
                            </div>
                          </a>
                        </div>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                        (change)="isActive ? '' : onSelectImagem($event)"
                      />
                    </label>
                  </div>
                </div>

                <div class="mt-2">
                  {{ previewText }}
                </div>
                <div class="flex justify-start w-full">
                  <div class="text-sm text-gray-500 mt-2 items-start">
                    {{ previewFooter }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
