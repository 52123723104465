import { DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from 'lib-trend-core'
import { IntegrationsRoutingModule } from './integrations-routing.module'
import { IntegrationsComponent } from './integrations.component'
import { WebhookIntegrationsFormComponent } from './webhook-integrations/webhook-integrations-form/webhook-integrations-form.component'
import { WebhookIntegrationsListComponent } from './webhook-integrations/webhook-integrations-list/webhook-integrations-list.component'
import { WaTemplateModule } from '../wa-template/webhook-integration-wa-template/webhook-integrations-wa-template.module'
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { WebhookIntegrationsItemComponent } from './webhook-integrations/webhook-integration-item/webhook-integrations-item.component'

@NgModule({
  imports: [
    SharedModule,
    IntegrationsRoutingModule,
    WaTemplateModule,
    PickerModule,
  ],
  declarations: [
    IntegrationsComponent,
    WebhookIntegrationsListComponent,
    WebhookIntegrationsFormComponent,
    WebhookIntegrationsItemComponent
  ],
  exports: [
    IntegrationsComponent,
    WebhookIntegrationsListComponent,
    WebhookIntegrationsFormComponent,
    WebhookIntegrationsItemComponent
  ],
  providers: [
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class IntegrationsModule {}
