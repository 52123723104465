import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { AbstractComponent, AlertModalComponent, AttendanceService, Channel, ChannelService, Contact, ContactEditComponent, ContactService, FirebaseService, MessageService, MessageTypeEnum, UploadTypeEnum, UtilHelper, WaTemplateResponse, WebhookIntegrationsService } from 'lib-trend-core'
import { BehaviorSubject } from 'rxjs'

export interface TemplateMessage {
  previewText: string
  previewHeader: string
  midiaHeader: string
  params: []
  requestKey?: string
}

interface actionObject {
  type: string
  field: string
  value: string
  valueHtml?: string
  typeMessage?: string
  template?: object
  templateParams?: Array<string>
}

@Component({
  selector: 'webhook-integration-wa-template-message',
  templateUrl: './webhook-integrations-wa-template-message.component.html',
  styleUrls: ['./webhook-integrations-wa-template-message.component.scss'],
})
export class WebhookWaTemplateMessageComponent extends AbstractComponent implements OnInit {
  @ViewChild('transferModal') transferModal: TemplateRef<any>
  @ViewChild('inputImagefile', { static: false })
  inputImagefile: ElementRef<HTMLInputElement>
  @ViewChild('inputVideofile', { static: false })
  inputVideofile: ElementRef<HTMLInputElement>
  @ViewChild('inputDocfile', { static: false })
  inputDocfile: ElementRef<HTMLInputElement>
  @ViewChild('inputAudiofile', { static: false })
  inputAudiofile: ElementRef<HTMLInputElement>

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false)
  loadingSpinnerTemplate$ = this.loadingSpinnerSubject.asObservable()

  file: File
  fileZero: any
  fileZeroSrc: string
  typeFile: string

  uploadHeader: boolean = false

  selectedTemplate: any
  selectedChannel: Channel
  selectedContact: Contact

  @Input() set template(template: WaTemplateResponse) {
    this.selectedTemplate = template
    this.extractTemplateVariables()
  }

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel
  }

  @Input() set contact(contact: Contact) {
    this.selectedContact = contact
  }

  @Input() set requests(requests: object) {
    this.requestsList = requests
  }
  @Input() isActive = false;

  @Output() templateParams = new EventEmitter()

  override templateVariableList = []

  previewText: string
  previewFooter: string
  previewHeader: any

  requestsList: object

  midiaHeader: any
  midiaType: string
  requestKey: string
  invalidMidiaHeader: string

  form: FormGroup

  webhookId: string;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    public contactService: ContactService,
    public channelService: ChannelService,
    public messageService: MessageService,
    private firebaseService: FirebaseService,
    public attendanceService: AttendanceService,
    public webhookIntegrationsService: WebhookIntegrationsService,
    private modalAlert: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Contact
  ) {
    super(injector)
  }

  ngOnInit(): void {
    this.setupForm()

    const webhookId = this.getParam('id')
    if (!!webhookId) {
      this.isNew = false
      this.webhookIntegrationsService
        .getById(webhookId)
        .subscribe((webhook) => {
          webhook.actions.map((action: actionObject) => {
            if (action['typeMessage'] === 'template' && action.template['id'] === this.selectedTemplate.id) {
              this.midiaHeader = action['midiaHeader']
              this.typeFile = action.template['components'][0]['format'] && action.template['components'][0]['format'].toLowerCase()

              action.templateParams.map((param, index) => {
                this.selectVarWebhookRequest(index + 1, param, true)
              })
            }
          })
        })
    }

    this.midiaType = this.selectedTemplate['headerComponent'] && UtilHelper.getTypeByMedia(this.selectedTemplate['headerComponent']['format'].toLowerCase())
  }

  private setupForm(): void {
    this.form = new FormGroup({
      image: new FormControl(''),
      attachment: new FormControl(''),
    })

    if (this.selectedTemplate['headerComponent'] && this.selectedTemplate['headerComponent']['example'] && !this.templateVariableList.includes('{{midiaHeader}}')) {
      this.templateVariableList.push('{{midiaHeader}}')
    }

    if (this.templateVariableList.length > 0) {
      this.templateVariableList.forEach((variable, index) => {
        this.form.addControl(
          `var${index + 1}`,
          new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(30),
          ])
        )

        this.form.get(`var${index + 1}`).valueChanges.subscribe((data) => {
          this.updatePreviewText()
        })

        this.form.valueChanges.subscribe((value) => {
          const params = this.templateVariableList.map(
            (_, idx) => this.form.get(`var${idx + 1}`).value
          )
          this.templateParams.emit({
            previewText: this.previewText,
            previewHeader: this.previewHeader,
            midiaHeader: this.midiaHeader,
            params: params,
            requestKey: this.requestKey
          })
        })
      })
    } else {
      this.updatePreviewText()
      this.templateParams.emit({
        previewText: this.previewText,
        previewHeader: this.previewHeader,
        midiaHeader: this.midiaHeader,
        params: [],
        requestKey: this.requestKey
      })
    }
  }

  private updatePreviewText(): void {
    let preview = this.selectedTemplate?.bodyComponent.text ?? ''

    this.templateVariableList.forEach((variable, idx) => {
      preview = preview.replace(
        `{{${idx + 1}}}`,
        this.form.get(`var${idx + 1}`).value
      )
    })

    this.previewText = preview
  }

  extractTemplateVariables(): void {
    if (!this.selectedTemplate) return

    this.typeFile = this.selectedTemplate['headerComponent'] && this.selectedTemplate['headerComponent']['format'].toLowerCase()

    this.previewText = this.selectedTemplate.bodyComponent.text
    this.previewFooter = this.selectedTemplate.footerComponent?.text ?? ''
    this.previewHeader =
      this.selectedTemplate.headerComponent?.example?.header_handle
        .map((url) => url)
        .toString()
    this.templateVariableList = []
    for (let i = 1; i <= 5; i++) {
      if (this.previewText.includes(`{{${i}}}`)) {
        this.templateVariableList.push(`{{${i}}}`)
      }
    }

    this.setupForm()
  }

  attachmentTemplate() {
    const midia = this.form.get('attachment')?.value

    if (midia) {
      const mediaType = this.helperExtension(midia)
      this.typeFile = UtilHelper.getTypeByMedia(MessageTypeEnum[mediaType])

      switch (mediaType) {
        case 'image':
          return midia
        case 'audio':
          return UtilHelper.on_audio
        case 'video':
          return UtilHelper.on_video
        case 'document':
          return UtilHelper.on_pdf
        default:
          return UtilHelper.on_file
      }
    } else {
      return UtilHelper.no_image_upload
    }
  }

  private helperExtension(
    midia: string
  ): 'image' | 'audio' | 'video' | 'document' | 'text' {
    const decodedUrl = decodeURIComponent(midia)

    const fileNameWithQuery = decodedUrl.split('/').pop() || ''
    const fileName = fileNameWithQuery.split('?')[0]

    const extension = fileName.split('.').pop()?.toLowerCase()

    const regex = /_(\d+)/
    const nameWithoutPart = extension.replace(regex, '')

    switch (nameWithoutPart.trim()) {
      case 'mp4':
        return 'video'
      case 'pdf':
        return 'document'
      case 'mp3':
        return 'audio'
      case 'png':
        return 'image'
      case 'jpg':
        return 'image'
      case 'jpeg':
        return 'image'
      default:
        return 'text'
    }
  }

  // removeAttachment(): void {
  //   if (this.chatbot.attachment) {
  //     this.chatbot.attachment = null;
  //     this.formGroup.patchValue({ attachment: null });

  //     if (this.chatbot._id) {
  //       this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
  //         next: () => this.alertService.success('Arquivo removido.'),
  //         error: (err) => this.alertService.error(`Erro ao atualizar banco de dados: ${err.error.message}`),
  //       });
  //     } else {
  //       this.alertService.error('ID do chatbot não encontrado.');
  //     }
  //   }
  // }

  onSelectImagem(event: Event): void {
    const target = event.target as HTMLInputElement
    const files: FileList = target.files
    this.file = files[0]

    if (this.file) {
      const fileType = this.file.type
      const fileSize = this.file.size
      const extensionFile = UtilHelper.getFileExtension(fileType)
      this.typeFile = UtilHelper.getMimeType(extensionFile)

      const fileTypeLimit = UtilHelper.getFileTypeLimit(fileType)

      if (fileTypeLimit) {
        if (fileSize > fileTypeLimit.maxSize) {
          this.openModalAlert(
            fileTypeLimit.alertTitle,
            fileTypeLimit.alertMessage,
            target
          )
          return
        }
      } else {
        this.openModalAlert('Tipo de arquivo não suportado', '', target)
        return
      }

      let reader = new FileReader()
      reader.onload = async (file) => {
        if (this.file) {
          this.loadingSpinnerSubject.next(true)
          this.uploadHeader = true
          const contents = file.target as FileReader
          const base64 = UtilHelper.arrayBufferToBase64(contents.result)
          const dateMilisecond = new Date().getTime()
          const filename: string = dateMilisecond.toString()
          this.firebaseService
            .uploadFile(this.file, filename, UploadTypeEnum.MESSAGE)
            .then((snapshot: { url: string }) => {
              this.loadingSpinnerSubject.next(false)
              this.midiaHeader = snapshot.url
              this.form.patchValue({ fileType: snapshot.url })

              const params = this.templateVariableList.map((_, idx) => this.form.get(`var${idx + 1}`).value)

              this.templateParams.emit({
                previewText: this.previewText,
                previewHeader: this.previewHeader,
                midiaHeader: this.midiaHeader,
                params: params,
                requestKey: this.requestKey
              })
            })
        }
      }
      reader.readAsArrayBuffer(this.file)
    }
  }

  private openModalAlert(
    title: string,
    message: string,
    inputElement?: HTMLInputElement
  ): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '500px',
      data: { title: title, message: message },
    })
    if (inputElement) {
      dialogRefAlert.afterClosed().subscribe(() => {
        inputElement.value = ''
      })
    }
  }

  selectVarWebhookRequest(varNumber: number, request: string, isEdit: boolean, variable?: string, midiaHeaderValue?: string): void {
    const requestValue = isEdit ? request : `{{${request}}}`
    this.form.get(`var${varNumber}`).setValue(requestValue)
    midiaHeaderValue = midiaHeaderValue?.replace(/ /g, "%20").replace(/\n/g, "");
    const urlExtension = this.domainHttpRegex.test(midiaHeaderValue) && this.helperExtension(midiaHeaderValue)
    const typeFileMatch = this.selectedTemplate['headerComponent'] && urlExtension === this.selectedTemplate['headerComponent']['format'].toLowerCase()

    let isMidiaHeaderIndex = false;
    if (variable === '{{midiaHeader}}') {
      isMidiaHeaderIndex = true;
      if (midiaHeaderValue && !this.domainHttpRegex.test(midiaHeaderValue)) {
        this.midiaHeader = ''
        this.form.get(`var${varNumber}`).setValue('')
        this.openModalAlert("A variável escolhida não é uma URL, é necessário que seja uma URL para que seja adicionado ao template!", '')
        return
      }

      if (midiaHeaderValue && !typeFileMatch) {
        this.form.get(`var${varNumber}`).setValue('')
        this.midiaHeader = ''
        this.openModalAlert("O tipo do arquivo da URL não é suportado neste template!", '')
        return
      }

      if (this.domainHttpRegex.test(midiaHeaderValue) && typeFileMatch) {
        this.midiaHeader = midiaHeaderValue
        this.requestKey = request
      }
    }

    const params = this.templateVariableList
      .filter((_, index) => varNumber !== index)
      .map((_, idx) => this.form.get(`var${idx + 1}`).value);

    this.templateParams.emit({
      previewText: this.previewText,
      previewHeader: this.previewHeader,
      midiaHeader: this.midiaHeader,
      params: params,
      requestKey: this.requestKey
    })
  }

  getIconForContentType(): string {
    this.form.get('attachment').setValue(this.midiaHeader);
    const contentType = this.attachmentTemplate()
    return contentType
  }

  // selectVarDepartmentName(varNumber: number): void {
  //   this.form
  //     .get(`var${ varNumber }`)
  //     .setValue(this.getCurrentUserUser().company.name)
  // }

  selectVarOperatorName(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue(this.getCurrentUserUser().name)
  }

  // selectVarLinkChannel(varNumber: number): void {
  //   this.form.get(`var${ varNumber }`).setValue(this.selectedChannel.name)
  // }

  // selectVarProtocol(varNumber: number): void {
  //   this.form.get(`var${ varNumber }`).setValue(this.selectedChannel._id)
  // }

  // emitTemplateData() {
  //   const params = this.templateVariableList.map((_, idx) => this.form.get(`var${ idx + 1}`).value);
  //   this.templateParams.emit({
  //     previewText: this.previewText,
  //     params: params,
  //     imageFile: this.fileZero
  //   });
  // }

  // onSelectImagem(event) {
  //   const files: FileList = event.target.files;
  //   this.fileZero = files[0];
  //   this.fileZeroSrc = URL.createObjectURL(this.fileZero);
  //   this.emitTemplateData();
  // }

  // clearFile() {
  //   this.fileZero = null;
  //   this.fileZeroSrc = null;
  // }
}
